.form-label-top-space {
    margin-top: 16px;
}

.form-group {
    margin-bottom: 16px;
}

.pricing-col {
    margin-top: 16px;
}

.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
}
